<template>
  <component is="PostList"  postType="anonymous"/>
</template>

<script>
import PostList from './PostList'

export default {
  name: "AnonymousList",
  components: {
    PostList
  }
}
</script>

<style scoped>

</style>